import React from "react"
import { Link } from "gatsby"

import Newsletter from "../components/newsletter"
import UbuntuFooterIcon from '../assets/svg/ubuntu-footer-icon.svg';

const Footer= () => (
  <footer>
    <div className="grid-container">
      <UbuntuFooterIcon/>
      <div className="footer--rights">
        <p>UBUNTU 2020 ®</p>
        <p>All rights reserved</p>
        <Link to="/privacy-policy" className="clickable">Privacy Policy</Link>
        <Link to="/legal" className="clickable">Legal</Link>
        <p><a className="clickable" href="https://latentestudio.com?ref=ubuntu" target="__blank" rel="noreferrer">Site by LATENTE</a></p>
      </div>
      <div className="footer--sitemap">
        <h6>Sitemap</h6>
        <Link className="clickable" to="/experiences">Experiences</Link>
        <Link className="clickable" to="/yoga">Yoga</Link>
        <Link className="clickable" to="/kaleidoscope">Kaleidoscope</Link>
        <Link className="clickable" to="/consulting">Consulting</Link>
      </div>
      <div className="footer--contact">
        <h6>Contact</h6>
        <a href="mailto:hello@experienceubuntu.com" className="clickable">hello@experienceubuntu.com</a>
        <a target="__blank" href="https://www.instagram.com/experienceubuntu/" className="clickable">Instagram</a>
        <a target="__blank" href="https://www.youtube.com/channel/UCWjRfWna9fDhtkF2-fww24w?view_as=subscriber" className="clickable">Youtube Channel</a>
      </div>
      <div className="footer--newsletter">
        <h6>Newsletter</h6>
        <Newsletter/>
      </div>
    </div>
  </footer>
)

export default Footer
