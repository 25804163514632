import React, {useState} from "react"

const Newsletter = () => {
  const
    [status, setStatus] = useState(null),
    [email, setEmail] = useState(''),
    [firstName, setName] = useState(''),
    FORM_URL = `https://app.convertkit.com/forms/1320034/subscriptions`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    try {
      const response = await fetch(
        FORM_URL,
        {
          method: 'post',
          body: data,
          headers: {
            accept: 'application/json',
          },
        }
      );
      setEmail('');
      setName('');
      const json = await response.json();
      if (json.status === 'success') {
        setStatus('SUCCESS');
        return;
      }
    } catch (err) {
      setStatus('ERROR');
    }
  };

  const handleInputChange = event => {
    const {value, name} = event.target;
    if(name === "email_address") {
      setEmail(value);
    } else {
      setName(value);
    }
  }

  return (
    <React.Fragment>
      <p className="subscription">Subscribe to our newsletter and receive a FREE 20 minute meditation!</p>
      <form action={FORM_URL} method="post" onSubmit={handleSubmit}>
        <input
          type="text"
          name="fields[first_name]"
          placeholder="First Name"
          onChange={handleInputChange}
          value={firstName}
          required
        />
        <input
          type="email"
          name="email_address"
          placeholder="Email"
          onChange={handleInputChange}
          value={email}
          required
        />
        <button type="submit" className="clickable">Subscribe</button>
      </form>
      {status === 'SUCCESS' && <p className="subscription success">Check your email for access to your freebie.</p>}
      {status === 'ERROR' && <p className="subscription error">Something went wrong! try again.</p>}
    </React.Fragment>
  )
}

export default Newsletter
