import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"

import "../styles/reset.scss"
import "../styles/custom.scss"
import "../styles/media.scss"
import '@brainhubeu/react-carousel/lib/style.css';

const Layout = ({ children }) => {
  return (
    <>
      <Header/>
        <main style={{paddingTop: 60}}>{children}</main>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
